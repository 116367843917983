import request from "@/utils/request";

const {ElMessage} = require("element-plus");

export default {
    name: "RepairInfo",

    components: {

    },

    data() {


        return {
            selectedYear: '', // 存储选中的年份
            selectedMonth: '', // 存储选中的月份
            dateRange: [], // 存储日期范围选择器的值
            creationtimeFilter: '', // 存储月份筛选器的值

            loading: true,

            disabled: false,
            judge: false,
            dialogVisible: false,
            detailDialog: false,
            search: "",
            currentPage: 1,
            pageSize: 10,
            total: 0,
            tableData: [],
            tableData1: [],
            detail: {},
            form: {
                id: "",
                deliverytime:"",
                customer: "",
                writers: "",
                creationtime: "",
                customerID: "",
                finish: "",
                audit: "",
                remark: "",
                collection: "",
                payment: "",
                serve: "",
                demand: "",
                manner: "",
                settlement: "",
                incentive: "",
                ordernumber: "",
            },


            rules: {
            },
        };
    },

    created() {


        this.load();
        this.loading = true;
        setTimeout(() => {
            //设置延迟执行
            this.loading = false;
        }, 1000);

    },
    methods: {
        // 处理日期范围选择器值变化
        handleDateRangeChange(value) {
            // value 是一个数组，包含开始日期和结束日期
            // 在这里根据选中的日期范围进行数据筛选或发起查询
        },
        // 处理月份筛选器值变化
        handleCreationtimeFilterChange(value) {
            // value 是选中的月份
            // 在这里根据选中的月份进行数据筛选或发起查询
        },


        async load() {
            const userData = JSON.parse(window.sessionStorage.getItem("user"));
            const yhnumber= userData.yhnumber;
            const userName = userData.username;

            // alert(userName)
            // alert(yhnumber)
            this.search = ""
            request.get("/building/kfSummary", {
                params: {
                    pageNum: this.currentPage,
                    pageSize: this.pageSize,
                    Customer: userName,
                },

            }).then((res) => {
                console.log(res);
                this.tableData = res.data.records.map(item => {
                    return {
                        ...item,
                        yhnumber: yhnumber
                    };
                });
                this.total = res.data.total;
                this.loading = false;

            });

        },
        handleEdit(row) {
            //获取详情



        },
        async load1(row) {

            const creationtime = row.creationtime; // 获取creationtime的值
            const remark = row.remark; // 获取remark的值
            const userData = JSON.parse(window.sessionStorage.getItem("user"));
            const userName = userData.username;


            request.get("/building/xqq", {
                params: {
                    pageNum: this.currentPage,
                    pageSize: this.pageSize,
                    Customer: userName,
                    Deliverytime: creationtime,
                    settlement: remark,
                },

            }).then((res) => {
                console.log(res);
                this.tableData1 = res.data.records;
                this.total = res.data.total;
                this.loading = false;


                // 打印tableData1的值
                console.log(this.tableData1);

                this.$forceUpdate();
                this.dialogVisible = true;

            });

        },

        closeDetailDialog() {
            this.detailDialog = false;
        },
        reset() {

            const userData = JSON.parse(window.sessionStorage.getItem("user"));
            const userName = userData.username;
            request.get("/building/findzd", {
                params: {
                    pageNum: 1,
                    pageSize: this.pageSize,
                    search: userName
                },
            }).then((res) => {
                console.log(res);
                this.tableData = res.data.records;
                this.total = res.data.total;
                this.loading = false;
            });
        },
        filterTag(value, row) {
            return row.finish === value;
        },
        filterTag1(value, row) {
            return row.audit === value;
        },
        filterTag2(value, row) {
            return row.settlement === value;
        },

        cancel() {
            this.$refs.form.resetFields();
            this.dialogVisible = false;
        },
        showDetail(row) {
            this.detailDialog = true;
            this.$nextTick(() => {
                this.detail = row;
            });
        },

        handleSizeChange(pageSize) {
            //改变每页个数
            this.pageSize = pageSize;
            this.load();
        },
        handleCurrentChange(pageNum) {
            //改变页码
            this.currentPage = pageNum;
            this.load();
        },
    },
};